.video {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video .botao {
    position: relative;
    top: -180px;
    right: -10px;
}

.video .botao button {
    padding: 3px 7px;
    cursor: pointer;
}