#carreira {
    width: 100%;
    padding: 90px 30px;
}

.carreiras {
    overflow-y: scroll;

    width: 50%;
}

.carreiras::-webkit-scrollbar {
    background-color: var(--secundario);
    width: 10px;
    border-radius: 6px;
}

.carreiras::-webkit-scrollbar-thumb {
    background-color: var(--botao);
    border-radius: 6px;
    cursor: pointer;
}

.carreiras::-webkit-scrollbar-thumb:hover {
    background-color: var(--titulo);
}

.carreiras-flex {
    height: 450px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.cargo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;

    margin-bottom: 30px;
}

.cargo h4 {
    color: var(--titulo);
}

.cargo .cabecalho {
    font-weight: 500;
}

.imagemControle {
    width: 50%;
}

.imagemControle img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, .4);
}

.cargo div:last-child {
    border-bottom: 2px solid var(--cinza-escuro);
    padding-bottom: 10px;
}

.cargo:last-child {
    margin-bottom: 0px;
}

.cargo:last-child .botao {
    border: none;
    padding-bottom: 0px;
}

.cargo button {
    padding: 5px 20px;
    background-color: var(--botao);

    cursor: pointer;
    outline: none;
    border: none;
}