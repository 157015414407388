/* Importações */

@import url(../src/styles/Header.module.css);
@import url(../src/styles/Apresentacao.module.css);
@import url(../src/styles/Sobre.module.css);
@import url(../src/styles/Projetos.module.css);
@import url(../src/styles/Tecnologias.module.css);
@import url(../src/styles/Carreira.module.css);
@import url(../src/styles/Certificados.module.css);
@import url(../src/styles/Rodape.module.css);
@import url(../src/styles/Video.module.css);

/* Variáveis */

:root {
  --verde: #28a745;
  --vermelho: #dc3545;
  --amarelo: #ffc107;
  --azul-claro: #17a2b8;
  --cinza-claro: #f8f9fa;
  --cinza-escuro: #343a40;
  --preto: #1f1f1f;

  --primario: #b9340b;
  --secundario: #c5be8b;
  --fundo: #3f261c;

  --botao: #cea45c;
  --titulo: #498379;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  background-color: var(--secundario);
  width: 10px;
  border-radius: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--botao);
  border-radius: 6px;
  cursor: pointer;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: var(--titulo);
}

a,
h1 {
  color: var(--preto);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

/* classNames */

.titulo {
  color: var(--preto);
}

@media (max-width: 1440px) {
  .sobre {
    height: auto;
  }

  .sobreMim {
    width: 100%;
  }

  .sobreExperiencia {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .sobre-apresentacao {
    flex-direction: column;
  }

  .sobre-apresentacao img {
    width: 100%;
  }

  .sobreExperiencia-card {
    width: 30%;
  }

  .tecnologiaDia-flex, .tecnologiaTrab-flex {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  header nav {
    justify-content: center;
  }

  header nav .navegacao {
    width: 100%;
  }

  header nav .titulo {
    display: none;
  }

  .carreiras-flex {
    height: 900px;
    flex-direction: column;
  }

  .carreiras, .imagemControle {
    width: 100%;
  }

  .apresentacao {
    flex-direction: column;
    height: auto;
    padding: 50px 0;
  }
}

@media (max-width: 768px) {
  header .navegacao {
    display: none;
  }

  .sobreExperiencia {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .sobreExperiencia-card {
    width: 100%;
    margin-top: 30px;
  }

  header nav {
    justify-content: start;
  }
  
  header .hamburguer {
    display: block;
  }

  .rodape {
    height: 100px;
  }

  .rodape-flex {
    flex-direction: column;
  }

  .rodape-flex div:first-child {
    border-right: none;
  }

  #tecnologias {
    height: auto;
  }

  .tecnologiasDiariamente {
    margin-bottom: 30px;
  }
  
  .tecnologiaDia-flex {
    flex-direction: column;
  }

  .tecnologiaTrab-flex {
    flex-direction: column;
  }
}