.apresentacao {
    width: 100%;
    height: 55vh;
    background-color: var(--branco);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

/* Direito */

.apresentacaoDireito h2 {
    font-size: 5rem;
}

.apresentacaoDireito h3 {
    font-size: 1.5rem;
    color: var(--titulo);
    font-family: 'Roboto';
    font-weight: 400;
}

.apresentacaoDireito-redesSociais {
    display: flex;
    justify-content: end;

    font-size: 2rem;
}

.apresentacaoDireito-redesSociais div {
    display: flex;
    justify-content: space-between;

    width: 200px;
}

.redesSociais-GitHub, .redesSociais-Whatsapp, .redesSociais-Linkedin {
    transition: 1s;
}

.redesSociais-GitHub:hover, .redesSociais-Whatsapp:hover, .redesSociais-Linkedin:hover {
    color: black;
    transition: 1s;
}

.redesSociais-Gmail {
    color: var(--preto);
    cursor: pointer;
}

/* Esquerdo */

.apresentacaoEsquerdo img {
    width: 350px;
    box-shadow: 4px 4px 5px var(--preto);
    border-radius: 50%;
}